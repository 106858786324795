import axios from 'axios';

export default {
    getSkills(tenantId, orgId, includeHidden = false) {
        let resource = window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills';
        if (includeHidden) {
            resource = resource + '?includeHidden=true'
        }
        return axios.get(resource);
    },

    getSkill(tenantId, skillId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Skills/' + skillId);
    },

    addSkill(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Skills', params);
    },

    updateSkill(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Skills', params);
    },

    deleteSkill(tenantId, skillId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Skills/' + skillId);
    },

    getPresencesForSkill(tenantId, orgId, skillId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills/' + skillId + '/Presences');
    },

    addSkillAssignment(tenantId, orgId, skillId, presenceId) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills/' + skillId + '/Presences/' + presenceId);
    },

    deleteSkillAssignment(tenantId, orgId, skillId, presenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Skills/' + skillId + '/Presences/' + presenceId);
    },
}