<template>
  <div class="response-team-list-item" @click.prevent="$emit('selected')">
    <div class="left">
    <scope-icon :scope="responseTeam.scope"></scope-icon> 
    </div>
    <div class="right">
      {{ responseTeam.displayName }}
    </div>
  </div>
</template>

<script>
import ScopeIcon from '../../../../components/ScopeIcon.vue';
export default {
  components: { ScopeIcon },
  props: ["responseTeam", "tenantId", "orgId"],
};
</script>

<style scoped lang="scss">
.response-team-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    min-width: 35px;
    display: flex;
    align-items: center;
  }

  .right {
    flex-grow: 1;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>