<template>
  <a-icon :style="{ 'font-size': '18px' }" :type="icon" />
</template>

<script>
export default {
  props: ["scope"],
  computed: {
    icon() {
      if (this.scope == 1) {
        return 'eye';
      }
      if (this.scope == 2) {
        // return 'cluster';
        return 'eye';
      }
      if (this.scope == 3) {
        return 'eye-invisible';
      }
      return 'eye';
    }
  }
};
</script>

<style>
</style>